.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  table {
    width: 100%;

    tr:last-child td {
      border-bottom: none;
    }
  }

  .originalContract {
    th,
    td {
      width: 21%;
    }

    th:first-child(),
    td:first-child() {
      width: 16%;
    }
  }
}
