.coTable {
  margin-top: 8px;
  width: 100%;

  th,
  td {
    width: 26%;
    text-align: right;
    font-weight: 400;
    padding: 6px 12px;
    border: 2px solid #c0c0c0;

    &:first-child {
      width: 40%;
      text-align: left;
    }

    &:last-child {
      width: 34%;
    }

    &[colspan='3'] {
      text-align: center;
    }
  }

  th {
    font-weight: bold;
    text-align: center;
    background-color: #f2f2f2;

    &:first-child {
      text-align: left;
    }
  }

  .thickBorder {
    border-top: 3px solid #b2b2b2;
  }

  /*td:not([colspan='3']) {
    text-align: right;
  }*/

  .expandable {
    cursor: pointer;

    i {
      margin-right: 8px;
    }
  }
}

.inputTextRight {
  input {
    text-align: right;
  }
}

.inputGroupWrap {
  span {
    margin-bottom: 10px;
  }
}

.noError {
  :global(.error) {
    display: none;
  }
}

.inlineFormField {
  > div:first-child {
    display: inline-flex;
    width: 365px;

    > div {
      position: relative;
      top: 2px;

      > label > span {
        margin-bottom: 18px;
      }
    }
  }

  > div:nth-child(2) {
    display: inline-flex;
    width: calc(100% - 365px + 48px); // 48px is sum of 4 paddings by 12px each
    padding-left: 0;

    > label {
      display: none;
    }
  }
}
