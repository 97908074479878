div.container {
    display: flex;
    width: 100%;
}

.inputContainer {
    width: 33.33%;
    position: relative;

    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    appearance: none;
    text-align: center;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.inputContainer:first-of-type input {
    border-radius: 0.375rem 0 0 0.375rem;
    border-right: 0;
}

.inputContainer:last-of-type input {
    border-radius: 0 0.375rem 0.375rem 0;
    border-left: 0;
}

.inputContainer input {
    text-align: right;
    padding: 0.375rem 0.75rem;
    box-sizing: border-box;
    border-radius: 0;
    padding-right: 50px;
}

.inputContainer span {
    margin-left: 5px;
    position: absolute;
    top: 8px;
    right: 35px;
}
