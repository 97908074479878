// remove arrows from number input

.numberInput {
  -moz-appearance: textfield;
  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}


.projectFieldDivider {
  margin: 0 0 20px 0;
  font-weight: 700;
  font-size: 16px;
  padding-top: 10px;
  border-bottom: 0px solid #e6e6e6;
}

.projectFieldDividerEdit {
  margin: 0 0 10px 0;
  font-weight: 600;
  padding-top: 10px;
  border-bottom: 1px solid #e6e6e6;
}


