.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto !important;
  min-height: 100%;
  position: relative;
}

.blocks {
  margin-bottom: 90px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
  }

  img {
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
  }
}

.wiplink {
  font-weight: bold;
  margin-top: 20px;
  display: inline-block;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000099;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  div {
    background: #fff;
    padding: 30px 20px;
    border-radius: 10px;
  }
}
