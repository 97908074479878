.wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.wrapper > a {
    align-self: flex-start;
}

.affiliationTable {
    width: 100%;
}

.affiliationTable td {
    vertical-align: top;
    padding: 3px;
}

.affiliationTable > tbody > tr > td:first-child {
  
}

.affiliationTable > tbody > tr > td:last-child {
    padding: 0;
    width: 70%;
}

.affiliationTable table {
    width: 100%;
}

.affiliationTable table td:first-child {
    width: 60%;
}

.contactBlock:last-child {
    margin-bottom: 0;
}

.contactBlockTitle {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 20px 3px;
    display: flex;
    justify-content: space-between;
}

.contactBlockTitle .contactBlockLink {
    color: initial;
}

.contactBlockTitle .contactBlockLink a:hover {
    color: initial;
}

.contactBlockTitle .contactBlockPencil {
    cursor: pointer;
    color: #d7d7d7;
}

.contactBlockTitle .contactBlockPencil:hover {
    color: #0c87de;
}

.title {
    font-size: 20px;
    font-weight: bold;
    display: block;
}

.inactiveText {
    color: #d7d7d7;
    font-size: 14px;
    font-weight: normal;
}
