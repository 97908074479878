.wrapper {
  position: relative;
  width: 100%;
}

.list {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 200;
  max-height: 192px;
  background-color: #fff;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%),
    0px 1px 8px 0px rgb(0 0 0 / 12%);
  overflow: auto;
}

.item {
  padding: 8px 12px;
}

.item:hover {
  cursor: pointer;
  background-color: #00000022;
}
