.rateInput {
    & :global(.ant-rate-star-zero) {
      & :global(.ant-rate-star-first),
      & :global(.ant-rate-star-second) {
        color: #000;
        filter: grayscale(100%) opacity(0.3);
      }
    }
  
    & :global(.ant-rate-star-half) {
      & :global(.ant-rate-star-second) {
        color: #000;
        filter: grayscale(100%) opacity(0.3);
      }
    }
  }