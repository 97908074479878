.bCalendar label {
  margin-bottom: 0;
}

.bCalEventWrap {
  transition: opacity 0.4s;
}

.bEventHighlight .bCalEventWrap:not(.bMatch) {
  opacity: 0.2;
}

.bEventHighlight .bCalEventWrap.bMatch {
  z-index: 10;
  opacity: 1;
}
