
/* css for lightbox items*/
.non-image-item-container {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust to your preferred height */
}

.pdfFileContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pdfFileContainer.pdfFileControls {
  display: block;
  justify-content: center;
  align-items: center;
}

.pdfFile {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width:auto;
  height: 100vh; /* Adjust to your preferred height */
}

.react-pdf__Page__canvas {
  display: block;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
}

.non-image-item-content {
  text-align: center;
  color: white;
  background-color: black;
}

.file-icon {
  width: 128px; /* Adjust the size as needed */
  height: 128px; /* Adjust the size as needed */
  background-color: black;
  border: 5px;
  border-color: white;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}

.file-name {
  font-size: 16px;
  font-weight: bold;
  color: white;
  align-items: center;
}

.file-size {
  font-size: 14px;
  font-weight: normal;
  color: white;
  align-items: center;
  
}
