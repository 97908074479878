textarea:focus,
input:focus {
    outline: none;
}

.fa-outline {
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    padding: 0.3px;
}

.ant-radio-group &.prevent-ant-rg-fs {
    font-size: unset !important;
}
