.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.infoTable {
    width: 100%;
    table-layout: fixed;
}

.infoTable thead{
    font-weight: bold;
}

.infoTable td {
    width: 18%;
    vertical-align: top;
}

.infoTable td:nth-child(1), td:nth-child(2) {
    width: 32%;
    vertical-align: top;
}

.infoTable td a {
    display: contents;
}

.title {
    font-size: 20px;
    font-weight: bold;
    display: block;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 2%;
}
