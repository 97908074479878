.image {
    height: 175px;
    width: 175px;
    
    border-radius: 50%;
    object-fit: cover;
}

.no-image {
    background-color: #00000011;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
}

.rect-image {
    width: 175px;
    height: 150px;
    border-radius: 5%;
    object-fit: contain;
}

.rect-icon {
    right: -10px !important;
}

.icon {
    position: absolute;
    bottom: 45px;
    right: 15px;
}

.icon-no-image {
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.remove-btn {
    margin-top: 8px;
}