:global(.b-sch-timerange).shaded,
:global(.b-timelinebase:not(.b-dragging-timerange) .b-sch-timerange).shaded:hover {
  z-index: 50;
}

:global(.b-sch-timerange).shaded {
  pointer-events: auto;
  cursor: default;
  background-color: rgba(250, 250, 250, 0.55);
  border-inline-end: 2px solid red;
}

:global(.b-grid-cell:last-child) {
  // set border-inline-end color from .b-grid-cell
  border-inline-end: 1px solid #e9eaeb;
}

.b-grid-splitter-buttons {
  display: none;
}

.schedulerBody > header {
  padding-top: 0;
}

.bToolbarItem label,
.bExportItem > label {
  margin-bottom: 0;
}

:global(.b-tree-cell-inner):has([data-disable-bullets='true']) {
  padding-left: 0;
  font-weight: 500;

  i {
    display: none;
  }
}

.print {
  display: none;

  @media print {
    display: flex;
  }

  .logoWrapper {
    max-width: 300px;
    max-height: 100px;
  }
}
