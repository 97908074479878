.activitySearch {
  padding: 6px 12px !important;
}

.activityHeader {
  border: 0px solid #5a5a5a;
}

.filterMenu {
  // menu should be round and modern

  border: 1px solid #5a5a5a;
  border-radius: 4px;
  background: #fff;
  padding: 6px 12px;

}

.filterButton {
  padding: 6px 6px !important;
  border: 1px solid #5a5a5a;
  background: #fff;
}

.sortButton{
  padding: 6px 122px !important;
  
  width: 32px;
  height: 32px;
}

.searchInput{
  width: 100%;
  height: 32px;
  border: 1px solid #5a5a5a;
  border-radius: 4px;
  padding: 0px 8px;
}

.filterTree {
  border: 0px solid #5a5a5a;
}

.filterActions {
  align-items: right;
  display: flex;
  justify-content: space-between;
  padding: 6px 0px 6px 0px !important;
}

.filterActions button {
  padding-top: 2px;
  padding-bottom: 2px;
  padding: 2px 6px !important;
  margin: 0px 4px;
  padding-bottom: 2px;
}

.subItem {
  margin-left: 0px;
}

.originBar {
  background-color: #bfdbfe;
  padding: 0px 10px;
}

.originLink {
  color: #1e40af;
  font-weight: 600;
  text-decoration: none;
}

.originLink:hover {
  text-decoration: underline;
}