.wrapper {
    gap: 10px;
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;

    label {
        display: none;
    }

    .sectionToolbar {
        display: flex;
        justify-content: flex-end;

        button {
            min-width: 170px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .rowWrapper {
        .addButton {
            width: 100%;
            background-color: #f0f3f8;
            border-radius: 15px;
            text-align: center;
            padding: 5px;
            color: #0c87de;
            font-weight: 600;
            transition: all .2s ease-in-out;

            &:hover {
                background-color: #0c87de;
                color: #f0f3f8;
            }

            &.disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }

        .actionButton {
            width: 24px;
            font-size: 20px;

            &.disabled {
                pointer-events: none;
                color: #6c757d !important;
            }

            &:hover:not(.disabled),
            &.active {
                opacity: 1;
                visibility: visible;
            }

            &:hover:not(.disabled) {
                font-weight: 900;
            }

            &.secondaryButton {
                color: #6c757d;
            }

            &.deleteButton {
                &:hover:not(.disabled) {
                    color: #DC3545;
                }
            }
        }

        .rowControlsWrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: baseline;
            gap: 4px;
            padding: 0;

            .deleteButton,.starButton.inactive {
                opacity: 0;

                &:before {
                    width: 22px;
                    display: flex;
                    justify-content: center;
                }
            }
        }

        &:hover,
        &:focus-within {
            .rowControlsWrapper i:not(.disabled) {
                transition: opacity .2s ease-in-out;
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

.inputWrapper {
    input {
        min-height: 38px;
        margin-bottom: 0 !important;
    }
}

.selectWrapper {
    div {
        margin-bottom: 0 !important;
    }
}
