body#LoginForm {
  background-image: url('https://hdwallsource.com/img/2014/9/blur-26347-27038-hd-wallpapers.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 10px;
}

.form-heading {
  color: #fff;
  font-size: 23px;
}

.panel h2 {
  color: #444444;
  font-size: 25px;
  margin: 0 0 8px 0;
  font-weight: 600;
}

.panel p {
  color: #777777;
  font-size: 14px;
  margin-bottom: 30px;
  line-height: 24px;
}

.login-form .form-control {
  background: #f7f7f7 none repeat scroll 0 0;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
}

.main-div {
  background: #f3f3f3 none repeat scroll 0 0;
  border-radius: 2px;
  max-width: 38%;
  padding: 50px 70px 70px 71px;
  border: 1px solid grey;
  margin: 120px auto 0;
}

.login-form .form-group {
  margin-bottom: 10px;
}

.login-form {
  text-align: center;
}
.forgot a {
  color: #777777;
  font-size: 14px;
}

.login-form .btn.btn-primary {
  background: #4c4c4c none repeat scroll 0 0;
  border-color: #4c4c4c;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0;
}

.forgot {
  text-align: left;
  margin-bottom: 30px;
}

.bottom-text {
  color: #ffffff;
  font-size: 14px;
  margin: auto;
}

.login-form .btn.btn-primary.reset {
  background: #ff9900 none repeat scroll 0 0;
}

.back {
  text-align: left;
  margin-top: 10px;
}

.back a {
  color: #444444;
  font-size: 13px;
  text-decoration: none;
}
