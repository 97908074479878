.wrapper {
    display: flex;
    gap: 8px;

    img {
        width: 60px;
        height: 60px;
        object-fit: contain;
    }

    i {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.title {
    font-size: 24px;
    font-weight: 600;
}

.tagWrapper {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}
