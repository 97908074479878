.minimap {
  position: relative;
  width: 100%;
  height: 100%;
}

.scrollcontent {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 225px);
}


.scrollcontent > div {
  padding-bottom: 20px;
}

.scrollcontent > div:last-child {
  padding-bottom: 0;
}

.minimap > span {
  left: 0;
  z-index: 0;
  width: 5px;
  position: absolute;
  pointer-events: none;
  background-color: #088496;
}

.minimap > div:last-child {
  border-bottom: none;
}

.minimap > div {
  position: relative;
  z-index: 1;
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid #ced4da;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: background-color .1s, height .5s;
  cursor: pointer;
  padding: 0 5%;
  min-height: 26px;
}

.minimap > div > span {
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
}

.minimap > div:hover {
  background-color: #088496;
  color: white;
}
