.wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.wrapper > a {
    align-self: flex-start;
}

.affiliationTable {
    width: 100%;
}

.affiliationTable td {
    vertical-align: top;
    padding: 3px;
}

.affiliationTable.twoColumns > tbody > tr > td:last-child {
    padding: 3px;
}

.affiliationTable > tbody > tr > td:last-child {
    padding: 0;
    width: 70%;
}

.affiliationTable table {
    width: 100%;
}

.affiliationTable table td:first-child {
    width: 60%;
}
