.lineItemSelect { [class*="-menu"] {
    width: 500px !important; 
    overflow: visible !important;
    z-index: 9999 !important;
    // show above all other elements
    position: absolute;
}
}

.dragHandle {
  cursor: move;
  display: flex;
  align-items: top;
  justify-content: right;
  width: 25px!important;
  color: #888;
  padding-right:0px !important;
  padding-top:1em;
  //overflow: hidden;
}

.disabledSelect {
    > div > div {
        > div {
            background-color: unset;
            border: none;

            > div:first-child {
                > div {
                    color: #212529;
                }
            }

            > div:last-child {
                display: none;
            }
        }
    }
}

.noLabel {
    > label {
        display: none;
    }
}

.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px!important;
    margin-top: 5px!important;
    width: 20px!important;
    height: 20px!important;
     &.form-check-input:checked {
        width: 40px!important;
        height: 20px!important;
        background-color: #0188ff!important;
    }
}


.controlBtn {
    display: flex;
    justify-content: center;
    left: 10px;
    margin-left: auto;
    padding-top: 8px;
    position: relative;
    width: 30px!important;
}

.toggleBtn {
    display: flex;
    justify-content: center;
    left: 10px;
    margin-left: auto;
    //padding-top: 8px;
    position: relative;
    width: 30px!important;
}

.addButton {
    font-size: 20px;
    color: #0188ff;
    padding: 0 5px;
    //margin-left: 20px;

    &:hover:not(.disabled) {
        color: #37a2ff;
    }
}

.deleteButton {
    font-size: 20px;
    color: #6c757d;
    padding: 0 2px;
    padding-right: 0px;

    &:hover:not(.disabled) {
        color: #DC3545;
    }
}

.inputWidthShort {
    width: calc((100% - 30px)/6) !important;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    span {
        cursor: pointer;
    }
    padding-right:0px;

}

.inputWidthDetailed {
    width: calc((100% - 90px)/9.5) !important;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    span {
        cursor: pointer;
    }

}

.inputWidthDetailedShort {
    width: calc((100% - 90px)/10/1.5) !important;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    span {
        cursor: pointer;
    }

}

.inputWidthDetailedExtraShort {
    width: calc((100% - 90px)/10/4) !important;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    span {
        cursor: pointer;
    }

}

// show bullet icon before div
.bulletStart::before {
    content:"• " !important;
}

.scopeEstimateContainer {
    // if page width is less than 1200px, make the width 100%, otherwise 1000px
    width: 100%;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 20px;

}

.inputWidthDetailedLong {
    width: calc((100% - 120px)*2.5/10 - 1em) !important;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    span {
        cursor: pointer;
    }

}

.inputWidth {
    width: calc((66.6% - 30px)/3) !important;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    span {
        cursor: pointer;
    }
}

.active {
    text-decoration: underline;
}

.percentage {
    position: relative;

    input {
        padding-right: 24px;
    }

    &::after {
        content: '%';
        position: absolute;
        top: 6px;
        right: 12px;
        font-size: 17px;
    }
}

.grey {
    color: #6c757d;
}
