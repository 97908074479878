@import 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700';
.navbar .admin {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn .admin{
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line .admin {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

.adminLogo{
width:40px;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
#sidebar img {
  width: 18%;
  padding: 7px;
  margin-left: 7px;
}
.admin .sidebar-space li a{
  margin-top: 25px;
  color: 'white' !important;
}
.admin .sidebar{
  width: 260px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  background: rgb(0, 0, 0);
  color: #000;
  background-color: #fff;
  transition: all 0.3s;
}

.admin .sidebar.active {
  margin-left: -250px;
}

.admin .sidebar .sidebar-header {
  padding: 1px 20px;
  background: rgb(76, 76, 76);
}

.admin .sidebar ul.components {
  padding: 20px 0;
}
.admin .sidebar ul li {
  color: #fff;
  padding: 10px;
  font-size: 18px;
}
.admin .sidebar ul p {
  color: #fff;
  padding: 10px;
  font-size: 18px;
}
.admin .sidebar ul a {
  text-decoration: none;
  color: #fff;
  font-weight: 400;
}
.log {
  padding-right: 5px;
}

.admin .sidebar ul li a {
  padding: 10px 10px;
  font-size: 14px;
  display: block;
  color: rgb(255, 255, 255);
}

.admin .sidebar ul li a:hover {
  color: #fff;
  font-weight: 700;
}

.admin .sidebar ul li .active {
  color: #fff;
  background-color: #6d6c6c;
  font-weight: 700;
}

.admin .accordion-button:focus {
  box-shadow: none !important;
}

.admin .sidebar ul li.active > a,
.admin a[aria-expanded='true'] {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
}

.admin-sidebar {
  min-width: 260px;
}

a[data-toggle='collapse'] {
  position: relative;
}

.dropdown-toggle::after .admin{
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content-SuperAdmin {
  width: 100%;
  padding: 33px 26px 16px 30;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 100vh;
  background-color: #efefef;
}

#content-SuperAdmin.active {
  width: 100%;
}

.admin .dash {
  background-color: #fff;
  padding: 12px;
}
.admin .dash-text {
  background-color: #fff;
  padding: 12px;
  padding-top: 45px;
}
.admin .dash i {
  display: inline-block;
  float: left;
  font-size: 30px;
  color: #5abaf3;
}
.admin .dash p {
  display: block;
  text-align: right;
  margin: 0;
}
.dash h6 {
  float: right;
  display: inline-block;
  margin-right: 0px;
  padding: 10px 0 0 0;
  font-weight: 600;
  margin: 0;
}
.dash:after {
  content: ' ';
  display: block;
  margin: 0 auto;
  position: absolute;
  width: 90%;
  top: 62%;
}
.data-text a {
  text-decoration: none;
}
nav.navbar-top {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding: 20px;
  background-color: rgb(76, 76, 76) !important;
  color: #fff;
  clear: both;
}
.contain-back {
  background-color: #fff;
  padding: 15px;
}

.admin .navbar-top .navbar-nav .nav-link {
  color: #fff !important;
}

.action-act a {
  color: #0c87de !important;
}
.admin a.deactivate-btn {
  color: red !important;
}
.action-act a:hover {
  text-decoration: none !important;
}

.fieldBoxRowAdmin .shareholder {
  width: 93% !important;
}

.registerBox {
  margin-bottom: 10px !important;
}

.statusColor {
  color: red;
}

.action-act span {
  display: inline !important;
}
#content-SuperAdmin .tableSection,
#content-SuperAdmin .tableSection {
  position: relative;
  padding-left: 228px;
  padding-right: 20px;
}
#content-SuperAdmin .tableSection .react-bs-container-header,
#content-SuperAdmin .tableSection .react-bs-container-body {
  padding-left: 0;
}
#content-SuperAdmin .tableSection .react-bs-container-header table th:nth-child(2),
#content-SuperAdmin .tableSection .react-bs-container-body table td:nth-child(2) {
  position: absolute;
  width: 210px;
  left: 20px;
}
#content-SuperAdmin .tableSection .react-bs-container-body table td:nth-child(2) {
  height: 50px;
}

.reset-pwd-box {
  margin-top: 65px;
}


#masterSubmenu {
  margin: 0;
}

#masterSubmenu a{
  background: none !important;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  .admin .sidebar {
    margin-left: -250px;
  }
  .admin .sidebar.active {
    margin-left: 0;
  }
  .admin .content {
    width: 100%;
  }
  .admin .content.active {
    width: calc(100% - 250px);
  }
  .admin .sidebarCollapse span {
    display: none;
  }
}
