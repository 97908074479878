.wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.header {
    display: flex;
    justify-content: space-between;
}

.header li i {
    background-color: #3681f8;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.infoTable {
    width: 100%;
    table-layout: fixed;
}

.infoTable thead{
    font-weight: bold;
}

.infoTable td {
    width: 25%;
    vertical-align: top;
}

.infoTable td:nth-child(1), td:nth-child(2) {
    width: 20%;
    vertical-align: top;
}

.infoTable td:nth-child(2) {
    width: 20%;
    vertical-align: top;
}

.infoTable td a {
    display: block;
}

.title {
    font-size: 20px;
    font-weight: bold;
    display: block;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 2%;
}
