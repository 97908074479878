.coTable {
  margin-top: 8px;
  width: 100%;

  th,
  td {
    width: 26%;
    text-align: right;
    font-weight: 400;
    padding: 6px 12px;
    border: 2px solid #c0c0c0;

    &:first-child {
      width: 40%;
      text-align: left;
    }

    &:last-child {
      width: 34%;
    }

    &[colspan='3'] {
      text-align: center;
    }
  }

  th {
    font-weight: bold;
    text-align: center;
    background-color: #f2f2f2;

    &:first-child {
      text-align: left;
    }
  }

  .thickBorder {
    border-top: 3px solid #b2b2b2;
  }

  /*td:not([colspan='3']) {
    text-align: right;
  }*/

  .expandable {
    cursor: pointer;

    i {
      margin-right: 8px;
    }
  }
}

.inputTextRight {
  input {
    text-align: right;
  }
}

.inputGroupWrap {
  span {
    margin-bottom: 10px;
  }
}

.inlineFormField {
  margin-left: 20px !important;
  width: calc(100% - 50px);

  > div > div {
    width: 100%;
  }

  label {
    display: none;
  }
}

.inlineFormCheckbox {
  > div {
    height: auto !important;
  }

  label {
    margin-top: 0 !important;
  }

  span > span {
    width: 28px;
    height: 28px;

    &:after {
      left: 33%;
    }
  }
}

.chevron {
  text-align: right;

  i {
    color: #3681f8;
    font-size: 20px;
    cursor: pointer;
  }
}

.textHint {
  font-size: 12px;
  text-align: right;
}
