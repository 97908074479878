.plainText input {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: transparent !important;
    border: solid transparent;
    border-width: 1px 0;
}

.noPadRight {
    padding-right: 0 !important;
}

.noPadRight > div:last-child {
    padding-right: 0;
}
