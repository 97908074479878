.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  table {
    width: 100%;

    tr:last-child td {
      border-bottom: none;
    }
  }
}
