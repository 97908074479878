.activePlusStageTable {
    tbody td {
        width: 20%;

        &:first-child {
            width: 40%;
        }
    }
}

.barContainer {
  min-width: 100px;
  background-color: #f0f0f0;
  border-color: #000000;
  height: 15px;
  border: 5px;
  border-radius: 1px;
  overflow: hidden;
  vertical-align: middle;
  
}

.bar {
  height: 100%;
  transition: width 0.5s ease-in-out;
}

.errorColumn {
  vertical-align: top;
  padding-left: 20px;
  max-width: 200px; 
  border-bottom: 0px;
}

.error {
  color: red;
  font-size: 0.9em;
  margin-bottom: 5px;
}