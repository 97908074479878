 .row {
     border-bottom: 1px solid #00000022;
     width: unset;
     display: flex;
     align-items: center;
     justify-content: flex-start;
 }

 .cell {
     display: flex !important;
     align-items: center;
     font-size: 12px;
     padding-left: 6px;
     line-height: 1.2;
 }

 .cell[data-last-cell='false'] {
     border-right: 1px solid #00000022;
 }

 .tableWrapper {
     padding: 1rem 0;

     table {
         border-spacing: 0;
         border: 1px solid rgba(0, 0, 0, 0.2);
         margin-top: 15px;

         tr {
             :last-child {
                 td {
                     border-bottom: 0;
                 }
             }
         }

         th,
         td {
             margin: 0;
             padding: 0.2rem;
             border-right: 1px solid rgba(0, 0, 0, 0.2);
             font-size: 12px;

             :last-child {
                 border-right: 0;
             }

             .resizer {
                 display: inline-block;
                 width: 10px;
                 height: 100%;
                 position: absolute;
                 right: 0;
                 top: 0;
                 transform: translateX(50%);
                 z-index: 1;
                 touch-action: none;
             }
         }

         tr:nth-child(even) {
             background-color: #f2f2f2;
         }

         tr:hover {
             background-color: #ddd;
         }

         th {
             background-color: rgb(245, 245, 245);
         }

         td:empty::before {
             content: '\\00a0';
             visibility: hidden
         }

         tr {
             display: flex;
         }


     }
 }