.coTable {
  margin-top: 8px;
  width: 100%;

  th,
  td {
    padding: 6px 12px;
    border: 2px solid #c0c0c0;
  }

  thead {
    th {
      font-weight: bold;
      text-align: center;
      background-color: #f2f2f2;
    }
  }

  tbody {
    td {
      vertical-align: middle;
      width: unset;
    }

    td:nth-child(1) {
      font-weight: bold;
      background-color: #f2f2f2;
      width: 40%;
    }
  }

  &.clickable tr:hover {
    background-color: #f9f9f9;
  }

  .thickBorder {
    border-top: 3px solid #b2b2b2;
  }

  .veryThickBorder {
    border-top: 5px solid #b2b2b2;
  }
}

.coModalBody {
  display: flex;
  flex-direction: column;
  padding: 16px 30px !important;
}

.coModalDialogExpanded {
  max-width: 1080px !important;

  .coTable td:nth-child(1) {
    width: 26%;
  }
}
