
.groupHeader {
  padding-left: 12px;

  &:hover {
    background-color: #DEEBFF;

    + div > div {
      background-color: #DEEBFF;
    }
  }

  + div > div {
    background-color: transparent;

    &:hover {
      background-color: #DEEBFF;
    }
  }

  > div {
    font-size: 14px;
    padding: 6px 0;
    font-weight: bold;
    color: #212529;

    span:first-child {
      display: flex;
      width: 100%;

      div {
        width: 100%;
        cursor: default;
      }
    }
  }
}

.group {
  display: flex;
  align-items: center;
  justify-content: space-between;
};

.groupBadge {
  background-color: #EBECF0;
  border-radius: 2em;
  color: #172B4D;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  min-width: 1px;
  padding: 2px 6px;
  text-align: center;
};
