.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  table {
    width: 100%;

    tr:last-child td {
      border-bottom: none;
    }

    td {
      padding: 0px 0px;
      
    }

    tr:first-child {
      th {
        padding: 0 0 20px;
      }
      
    }

    th {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      text-align: left;
      padding: 0 0 8px;
    }

    th:first-child,
    td:first-child {
      font-weight: 700;
      width: 175px !important;
    }

    tr:first-child th:first-child {
      font-size:20px;
    }

    th:not(:first-child),
    td:not(:first-child) {
      text-align: left;
      padding-left: 15px;
      width: 20%;
    }

    th:last-child,
    td:last-child {
      width: 100px;
      margin-left: 20px;
      text-align:right;
    }

    
  }

  
  
}
