.menu-item--country-name {
    font-size: smaller;
    display: none;
}

.menu-item--country-code {
    width: 35px;
}

.menu-item--flag {
    border: 1px solid #00000022;
    min-width: 24px;
}

.menu-item--item-container {
    display: grid;
    grid-template-columns: auto 40px 1fr;
    align-items: center;
    gap: 8px;
}

[class$="ValueContainer"] .menu-item--country-name {
    display: none;
}
