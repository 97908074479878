.coTable {
  margin-top: 8px;
  width: 100%;

  th,
  td {
    padding: 6px 12px;
    border: 2px solid #c0c0c0;
  }

  thead {
    th {
      font-weight: bold;
      text-align: center;
      background-color: #f2f2f2;
    }
  }

  tbody {
    td {
      vertical-align: middle;
      width: unset;
    }
  }

  &.clickable tr:hover {
    background-color: #f9f9f9;
  }
}

.statusTable {
  td:nth-last-child(1) {
    text-align: right;
  }

  td:nth-child(1) {
    width: 65%;
  }
}

.chevron {
  text-align: right;

  i {
    color: #3681f8;
    font-size: 20px;
    cursor: pointer;
  }
}
