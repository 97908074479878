.is-edit-table {
  cursor: pointer;
}

.is-edit-table:hover .is-edit-table-cell {
  transition: all 0.3s ease;
}

.is-edit-table:hover .is-edit-table-cell {
  background-color: #ccc;
}
