.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto !important;
  min-height: 100%;
  position: relative;

  .projectList {
    > div {
      &:last-child {
        > div {
          &::after {
            display: none;
          }
        }
      }

      > div {
        &::after {
          content: '';
          display: block;
          padding-top: 14px;
          border-bottom: 1px solid #e8e8e8;
        }
      }
    }
  }
}

.projectImportGrid {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
}
