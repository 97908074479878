.styledFormCheckbox {
  align-items: center;
  margin-bottom: 12px;

  &:global:not(.row) {
    gap: 1rem;
  }

  label {
    display: inline-flex !important;

    > span {

      &:after {
        border-radius: 0.375rem;
      }

      > span {
        width: 28px;
        height: 28px;
        border-radius: 0.375rem;

        &:after {
          left: 33%;
        }
      }
    }
  }
}
