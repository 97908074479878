:global(.b-sch-timerange).shaded,
:global(.b-timelinebase:not(.b-dragging-timerange) .b-sch-timerange).shaded:hover {
  z-index: 50;
}

:global(.b-sch-timerange).shaded {
  pointer-events: auto;
  cursor: default;
  background-color: rgba(250, 250, 250, 0.55);
  border-inline-end: 2px solid red;
}

.schedulerBody > header {
  padding-top: 0;
}

.bToolbarItem label,
.bExportItem > label {
  margin-bottom: 0;
}
