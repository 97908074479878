:global(.modal > .modal-dialog).modalDialog {
  max-width: 960px;
}

.scrollspy {
  max-height: calc(75vh - 100px);
}

.preloader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
}

.react-datepicker__close-icon {
  display: flex;
}

