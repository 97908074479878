table tr.tableRow {
    display: flex;
    width: 100%;

    td:last-child > div {
        display: flex;
        gap: 4px;
        height: 100%;
        align-items: center;
    }

    &:last-child td:not(:last-child) {
        border-right: none;
    }

    &:not(:last-child) {
        td:not(:last-child) {
            border-right: none;
            border-bottom: none;
        }

        td:last-child {
            border-bottom: none;
        }
    }
}
